.setButton{
    border: none;
    padding: 7px;
    border-radius: 5px;
    background-color: green;
    font-weight: 500;
    margin-right: 20px;
    cursor: pointer;
    color: white;
}

.rightbarTitle{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.profileimage{
    margin-bottom:20px;
}

.formImage{
    width:50%;
}
