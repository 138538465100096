.login {
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 70%;
  /* height: 70%; */
  display: flex;
}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loginRight {
  width: 75%;
}
.loginLeft {
  padding-right: 8px;
  width: 25%;
}
.loginLogo {
  font-size: 50px;
  font-weight: 800;
  color: #1775ee;
  margin-bottom: 10px;
}

.loginDesc {
  font-size: 24px;
}

.loginBox {
  height: auto !important;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #1775ee;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginButton:focus {
  outline: none;
}

.loginButton:disabled {
  cursor: not-allowed;
}

.loginForgot {
  text-align: center;
  color: #1775ee;
}

.loginRegisterButton {
  width: 60%;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginBox2 {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login {
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerWrapper {
  width: 90%;
  /* height: 70%; */
  display: flex;
}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginLogo {
  font-size: 30px;
  font-weight: 800;
  color: #00421b;
  margin-bottom: 10px;
}

.loginDesc {
  font-size: 24px;
}

.loginBox {
  /* height: 485px; */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.loginBox3 {
  /* height: 300px; */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #00421b;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginForgot {
  text-align: center;
  color: #00421b;
}

.loginRegisterButton {
  width: 60%;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b72a;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.loginBox2 {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #00421b;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .loginLeft {
    display: none;
  }
}
