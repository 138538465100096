.listItem-wrap img {
  width: 100px;
  border-radius: 15px;
  height: 200px;
  object-fit: cover;
}
.listItem-wrap header {
  display: flex;
  justify-content: start;
  margin: 1rem 0 1rem 0;
  align-items: center;
}

.listItem-wrap header span {
  font-size: 0.7rem;
  background-color: #a7f175;
  padding: 0.5rem;
  border-radius: 10px;
}
.listItem-wrap footer {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listItem-wrap footer p:first-child span {
  color: rgba(0, 0, 0, 0.26);
  font-weight: 600;
}

.button-38 {
  background-color: #ffffff;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 7px;
}

.button-38:hover {
  background-color: rgb(249, 250, 251);
}

.button-38:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-38:focus-visible {
  box-shadow: none;
}
