.rightbar {
  flex: 3.5;
  padding-left: 5px;
}

.rightbarWrapper {
  padding: 20px 20px 0 0;
}

.birthdayContainer {
  display: flex;
  align-items: center;
}

.birthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.birthdayText {
  font-weight: 300;
  font-size: 15px;
}

.rightbarAd {
  width: 100%;
  border-radius: 10px;
  margin: 30px 0;
}

.rightbarTitle{
    margin-bottom: 20px;
}

.rightbarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.rightbarTitle{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.rightbarInfo{
  margin-bottom: 30px;
}

.rightbarInfoItem{
  margin-bottom: 10px;
}

.rightbarInfoKey{
  font-weight: 500;
  margin-right: 7px;
  color: #555;
}

.rightbarInfoValue{
  font-weight: 300;
}

.rightbarFollowings{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rightbarFollowing{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.rightbarFollowingImg{
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.rightbarFollowButton{
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  background-color: #1872f2;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.rightbarFollowButton:focus{
  outline: none;
}

.capitalize {
  text-transform: capitalize;
}

.editform{
  height: 38px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 14px;
  padding-left: 7px;
  margin-bottom: 5px;
}

.editButton{
  font-weight: 500;
    margin-right: 15px;
    color: #555;
    font-size: 15px;
}
.editButton:hover{
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}