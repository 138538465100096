.label {
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.input-group {
  margin-bottom: 2rem;
}
.label-range {
  margin-bottom: 2.5rem;
}
.capitalize {
  text-transform: capitalize;
}
