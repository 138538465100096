.list-wrap {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: repeat(0, 100px);
    align-content: center;
    justify-content: center;
    justify-items: start;
   
  }
  .listItem-wrap img {
  
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1vh;
      border: 1px solid rgb(0, 0, 0, 0.26);
  }